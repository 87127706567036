import React, { useState } from 'react';
import ModalMessage from 'components/modal/ModalMessage';
import { isFunction } from 'extra/functions';

const ModalMessageContext = React.createContext();

export const ModalMessageContextProvider = props => {

	const [ modal, setModal ] = useState( null );

	return (
		<ModalMessageContext.Provider value={{
			loading: () => setModal({ loading: true, heading: "Wczytywanie" }),
			close: () => setModal( null ),
			setContent: ( content, close_enabled, rest ) => { 
				setModal({ 
					...rest,
					content, 
					closeHandler: close_enabled 
						? () => { 
							setModal( null );
							isFunction( close_enabled ) && close_enabled()
						} 
						: null 
				})
			},
		}}>
			{ modal && <ModalMessage {...modal } /> }
			{ props.children }
		</ModalMessageContext.Provider>
	)
}


export default ModalMessageContext;