import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import API, { checkbox_names } from "api/API";
import districts_response from "extra/cache/districts_response.json";
import streets_response from "extra/cache/streets_response.json";
import useDidChange from 'hooks/useDidChange';
import { replacePolishLetters, capitalizeStr } from "extra/functions";

const STATUSES = {
	1: { proper_index: 0, label: "Do weryfikacji", color: "#805F0A", font_color: "#000" },
	2: { proper_index: 4, label: "Odrzucony z przyczyn regulaminowych", color: "#df0024", font_color: "#fff" },
	3: { proper_index: 1, label: "Przyjęty do realizacji", color: "#e0b60e", font_color: "#000" },
	4: { proper_index: 3, label: "Nie będzie zrealizowany", color: "#000", font_color: "#fff" },
	5: { proper_index: 2, label: "Zrealizowany", color: "#4e8430", font_color: "#000" },
}


const AdminDataContext = React.createContext(); 

export const AdminDataContextProvider = props => {

	const location  = useLocation();

	const [ loading, setLoading ] = useState( true );

	const [ districts, setDistricts ] = useState( null );
	const [ streets, setStreets ] = useState( null );

	const [ statuses, setStatuses ] = useState( {} );
	const [ statuses_options, setStatusesOptions ] = useState( null );
	const [ statistics, setStatistics ] = useState( null );
	const [ agreements, setAgreements ] = useState( null );
	const [ checkboxes, setCheckboxes ] = useState( null );
	const [ categories, setCategories ] = useState( null );
	const [ categories_options, setCategoriesOptions ] = useState( null );
	const [ general_map_tile_url, setGeneralMapTileURL ] = useState( null );
	const [ satellite_tile_url, setSatelliteTileURL ] = useState( null );
	const [ general_map_max_zoom, setGeneralMapMaxZoom ] = useState( null );
	const [ satellite_map_max_zoom, setSatelliteMapMaxZoom ] = useState( null );
	const [ map_banner, setMapBanner ] = useState( null );


	const getAllDistricts = () => {
		return new Promise( resolve => {

			setDistricts(
				districts_response.districts && !!districts_response.districts.length
					? districts_response.districts
						.sort(( a, b ) => {
							return replacePolishLetters( a.d ) > replacePolishLetters( b.d ) ? 1 : -1;
						})
						.map( item => ({
							value: item.i,
							label: item.d
						}))
					: null 
			);

			resolve();
		});
	}


	const getAllStreets = () => {
		return new Promise( resolve => {
			setStreets( 
				streets_response.streets && !!streets_response.streets?.length
					? streets_response.streets
						.filter( item => item.s !== "<brak ulicy>" )
						.sort(( a, b ) => {
							return capitalizeStr( replacePolishLetters( a.s )) > capitalizeStr( replacePolishLetters( b.s )) ? 1 : -1;
						})
						.map( item => ({ 
							value: item.i, 
							label: item.s, 
							district_id: item.d 
						}))
					: null
			);

			resolve();
		})
	}


	const getStatuses = () => {
		return API.getStatuses()
			.then( res => {
			
				const statuses = res.data.statuses; 
				const final_statuses = {};
				const statuses_options = [];

				Object.keys( statuses ).forEach( id => {

					const { proper_index, label } = STATUSES[ id ];
					const STATUS_CODE_NAME = statuses[ id ];

					final_statuses[ STATUS_CODE_NAME ] = {...STATUSES[ id ], id };
					statuses_options[ proper_index ] = ({ value: STATUS_CODE_NAME, label });
				});
			
				setStatuses( final_statuses );
				setStatusesOptions( statuses_options );
			});
	}


	const getStatistics = () => {
		return API.getStatistics()
			.then( res => setStatistics( res.data.stats ))
	}


	const getAgreements = () => {
		return API.getAgreements()
				.then( res => setAgreements( res?.data?.agreements ));
	}


	const getCheckboxesContent = () => {

		const checkboxes = {};

		return Promise.all(
			checkbox_names.map( name => (
				API.getCheckbox( name )
				.then( res => { 
					const value = res.data?.value && res.data?.value !== "<p></p>\n" ? res.data.value : "";
					checkboxes[ name ] = value;	
				})
			))
		).then(() => setCheckboxes( checkboxes ))
	}


	const getCategories = () => {
		return API.getCategories()
				.then( res => {

					const ALL_CATEGORIES = [ ...res.data.categories ].sort(( a ,b ) => a.sort > b.sort ? 1 : -1 );
					let categories = [], subcategories = [];

					ALL_CATEGORIES.forEach( category => {
						!category.parentId
							? categories.push( category )
							: subcategories.push( category );
					});
					
					subcategories.forEach( subcategory => {
						const parent = categories.find( category => category.id === subcategory.parentId );
						if ( parent ) {
							if ( !parent.subcategories ) parent.subcategories = [];
							parent.subcategories.push( subcategory );
						}
					});

					
					const categories_options = categories.map( item => (
						{
							value: item.id,
							label: item.categoryName,
							subcategories: item.subcategories
								? item.subcategories.map( subitem => ({ value: subitem.id, label: subitem.categoryName }))
								: null 
						}
					));
							
					setCategoriesOptions( categories_options );
					setCategories( ALL_CATEGORIES );
				})
	}


	const getGeneralMapTileUrl = () => {
		return API.getGeneralMapTileURL()
				.then( res => setGeneralMapTileURL( res.data?.value ))
	}


	const getSatelliteTileURL = () => {
		return API.getSatelliteMapTileURL()
				.then( res => setSatelliteTileURL( res.data?.value ))
	}


	const getGeneralMapMaxZoom = () => {
		return API.getGeneralMapMaxZoom()
				.then( res => setGeneralMapMaxZoom( res.data?.value ))
	}


	const getSatelliteMapMaxZoom = () => {
		return API.getSatelliteMapMaxZoom()
				.then( res => setSatelliteMapMaxZoom( res.data?.value ))
	}
	 

	const getMapBanner = () => {
		return API.getMapBanner()
			.then( res => setMapBanner( res.data?.value !== "<p></p>\n" ? res.data.value : "" ))
	} 


	const getSubcategoriesOptions = category => categories_options.find( item => String( item.value ) === String( category ))?.subcategories || [];

	const getCategoryById = id => categories.find( item => String( item.id ) === String( id ));

	const getCategoryAndSubcategoryLabels = category_id => {

		const category = getCategoryById( category_id );
		const is_subcategory = !!category.parentId;

		let category_label = is_subcategory ? getCategoryLabelById( category.parentId ) : category.categoryName || "-";
		let subcategory_label = is_subcategory ? category.categoryName : "-";

		return [ category_label, subcategory_label ];
	}

	const getCategoryLabelById = id => getCategoryById( id )?.categoryName || "";

	const getStatusColor = status => statuses[ status ]?.color;

	const getStatusLabel = status => statuses[ status ]?.label;

	useEffect(() => {

		Promise.all([
			getAllDistricts(),
			getAllStreets(),
			getStatuses(),
			getStatistics(),
			getAgreements(),
			getCheckboxesContent(),
			getCategories(),
			getGeneralMapTileUrl(),
			getSatelliteTileURL(),
			getGeneralMapMaxZoom(),
			getSatelliteMapMaxZoom(),
			getMapBanner()
		]).then(() => setLoading( false ));

	}, []);

	useDidChange(() => getStatistics(), [ location ]);

	return (
		<AdminDataContext.Provider value={{
			loading,
			districts,
			streets,
			statuses,
			statistics,
			statuses_options,
			agreements,
			checkboxes,
			general_map_tile_url,
			satellite_tile_url,
			general_map_max_zoom,
			satellite_map_max_zoom,
			categories_options,
			map_banner,
			getSubcategoriesOptions,
			getCategoryById,
			getCategoryAndSubcategoryLabels,
			getCategoryLabelById,
			getStatusColor,
			getStatusLabel
		}}>
			{ props.children }
		</AdminDataContext.Provider>
	)
}


export default AdminDataContext;