import React from 'react';
import Menu from 'components/navigation/Menu';
import AppTotals from 'components/sidebar/AppTotals';
import "./sidebar.scss";

const Sidebar = () => (
	<div className="sidebar has-scrollbar">
		<Menu/>
		{/* <AppTotals/> */}
	</div>
)

export default Sidebar;