import React from 'react';
import ModalHeader from '../ModalHeader';
import ModalButtons from '../ModalButtons';
import Loader from 'components/general/Loader';
import './modal.scss';

const ModalMessage = ({ loading, heading, content, text_align, closeHandler, button_label }) => (
	<div className="black-overlay">

		<div className="modal">
			
			<ModalHeader 
				heading={ heading } 
				closeHandler={ closeHandler }
			/>

			<div className="modal__body df-column">
				<div className="modal__content has-scrollbar" style={{ textAlign: text_align || "center" }}>
					{ !loading ? content : <Loader style={{ minHeight: "120px" }}/> } 
				</div>

				{ button_label && 
					<ModalButtons buttons={
						[{ children: button_label, onClick: closeHandler }]
					}/>
				}
			</div>
		</div>

	</div>
)


export default ModalMessage;