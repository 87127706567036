import React from 'react';
import Button from 'components/buttons/Button';

const ModalButtons = ({ buttons }) => (
	<div className="modal__buttons df-centered">
		{ buttons && !!buttons.length && 
			buttons.map(( button, i ) => (
				<Button key={ i } {...button }>
					{ button.children }
				</Button>
			))
		}
	</div>
)

export default ModalButtons;