
const getDetectVariableTypeFunc = type => {
	return v => toString.call( v ) === `[object ${ type }]`
};

export const ucFirst = str => str.substr(0, 1).toUpperCase() + str.substr(1);

export const isFunction = getDetectVariableTypeFunc( "Function" );

export const isObject = getDetectVariableTypeFunc( "Object" );

export const isNumber = getDetectVariableTypeFunc( "Number" ); 

export const isString = getDetectVariableTypeFunc( "String" );

export const addZeroIfNeeded = num => num <= 9 ? `0${num}` : num;

export const getTableRowsInPage = ( rows, page, rows_per_page = 4 ) => {
	const start = ( page * rows_per_page ) - rows_per_page;
	const end = page * rows_per_page;

	return rows.slice( start, end );
}

export const getRandomInteger = ( min, max ) => {
	const rand = min + Math.random() * (max + 1 - min);
	return Math.floor( rand );
};

export const cutText = ( text, size ) => {
	if ( text.length <= size ) return text;
	return text.slice( 0, size ) + "...";
}

export const getRandomItemFromArray = array => array[ getRandomInteger( 0, array.length - 1)];

export const formDataReducer = ( state, action ) => {
 	
	let copied_state = {...state };

	switch ( action.type ) {

		case "remove":
			Array.isArray( action.name )
				? action.name.forEach( n => { delete copied_state[ n ]})
				: delete copied_state[ action.name ];
			return copied_state;

		case "remove_all":
			for ( let k in copied_state ) delete copied_state[ k ];
			return copied_state;	

		case "update":
		default: 
			Array.isArray( action )
				? action.forEach(({ name, value }) => copied_state[ name ] = value )
				: copied_state[ action.name ] = action.value;
				return copied_state;
	}
}

export const loadScript = ( script, parent_el ) => {
	
	const src = isObject( script ) ? script.src : script;
	const script_el = document.createElement('script');
	const place = parent_el || document.body; 

	script_el.type = script.type ||"text/javascript";
	script_el.async = script.async || true;
	script_el.id = script.id || "";
	script_el.src = src;

	place.appendChild( script_el );
}

export const getUrlParams = () => {

	const url = window.location.href;
	const result = {};
	const search_part = url.split("?")?.[1];

	if ( search_part ) {
		const params = search_part.split("&");
		
		if ( params && !!params.length ) {
			
			params.forEach( item => {
				const [ name, value ] = item.split("=");
				result[ name ] = value; 
			})
		}
	}

	return result;
}

export const fieldsValidation = fields => {
	
	const errors = {};
	let success = true;

	const getError = ( type, value, repeat_value ) => {
		switch ( type ) {
			
			case "required": return !value && "Musisz zaznaczyć wymagane pola!";

			case "message": return !value && "Musisz napisać wiadomość!";

			case "email": return !/^[a-zA-Z0-9-_+.]+@[a-zA-Z-_.0-9]{2,}\.[a-zA-Z]{2,4}$/.test( value ) && "Musisz podać swój adres e-mail!";

			case "email_repeat": return ( !value || value !== repeat_value ) && "Musisz powtórzyć swój adres e-mail!"; 

			case "repeat": return value !== repeat_value && "Podane pola nie są zgodne";

			case "captcha": return !value && "Niepoprawny wynik!";

			case "files": return !value.files?.length && "Dodaj minimum jedno zdjęcie.";

			case "categories": return !value && "Musisz wybrać kategorię!";

			case "subcategories": return !value && "Musisz wybrać podkategorię!";

			case "description": return !value && "Musisz opisać problem!";
		}
	}

	fields.forEach( field => {
		
		const { type, name, value, repeat_value } = field;
		const error = getError( type, value, repeat_value );
		
		if ( error ) {
			errors[ name ] = error;
			success = false;
		}
	});

	return { success, errors };
}

export const getPopupPosition = ({ center_x, center_y, width, height, container_el }) => {
	
	if ( !width || !height || !center_x || !center_y || !container_el ) return null;
	
	const container_coords = container_el.getBoundingClientRect();

	const popup_left = center_x - (width / 2); 
	const popup_right = center_x + (width / 2);
	const popup_top = center_y - (height / 2);
	const popup_bottom = center_y + (height / 2);

	const left =
		popup_right >= container_coords.right
		? container_coords.right - width - 5
		: popup_left <= container_coords.left
			? container_coords.left + 5
			: popup_left

	const top = 
		popup_top <= container_coords.top
		? container_coords.top + 5
		:  popup_bottom >= container_coords.bottom
			? container_coords.bottom - height - 5
			: popup_top;

	return { left, top };
}

export const funcDelayDecorator = function( fn, delay = 300 ) {

	let timer;

	return function() {
		clearTimeout( timer );
		timer = setTimeout(() => fn( ...arguments ), delay );
	}
}

export const blurAllInputs = () => {
	const all_elements = document.querySelectorAll( "input, select" );
	all_elements && all_elements.length && Array.prototype.forEach.call( all_elements, el => el.blur());
}

export const getMapBounds = items => {

	if ( Array.isArray( items ) && !!items.length ) {
		
		let min_lat, max_lat = 0;
		let min_lng, max_lng = 0;

		for ( let i = 0; i < items.length; i++ ) {
			const { lat, lng } = items[i];

			if ( lat && lng ) {
				if ( !min_lat ) min_lat = lat;
				if ( !min_lng ) min_lng = lng;

				if ( +lat < min_lat ) min_lat = lat;
				if ( +lat > max_lat ) max_lat = lat;

				if ( +lng < min_lng ) min_lng = lng;
				if ( +lng > max_lng ) max_lng = lng;
			}
		}

		return [
			[ +min_lat, +min_lng ],
			[ +max_lat, +max_lng ]
		];
	}

	return null;
};


export const getIntFromAddressNum = address_num => {
	return /[a-z]/i.test( address_num )
		? +address_num.replace(/[a-z]/gi, "")
		: +address_num
}


export const capitalizeStr = str => str.slice(0,1).toUpperCase() + str.slice(1).toLowerCase();

export const replacePolishLetters = str => {

	const letters_to_replace = {
		"ą": "a",
		"Ą": "A",
		"ć": "c",
		"Ć": "C",
		"ę": "e",
		"Ę": "E",
		"ł": "l",
		"Ł": "L",
		"ń": "n",
		"Ń": "N",
		"ó": "o",
		"Ó": "O",
		"ś": "s",
		"Ś": "S",
		"ź": "z",
		"Ż": "Z",
		"ż": "z",
		"Ż": "Z"
	}

	return str.split("").reduce(( result, current ) => {
		result += letters_to_replace[ current ] || current;
		return result;
	}, "")
}