import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import App from './App';
import reportWebVitals from './reportWebVitals';


ReactDOM.render(
	<GoogleReCaptchaProvider
		reCaptchaKey={ window.config.CAPTCHA_KEY }
		language="pl"
	>
		<React.StrictMode>
			<App />
		</React.StrictMode>
	</GoogleReCaptchaProvider>,
  document.getElementById('lublin-app')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
