import React from 'react';
import { isFunction } from 'extra/functions';
import { CloseCircleIcon } from 'svg/icons';

const CloseButton = props => (
	<a href="#" className="df-centered close-button" onClick={ e => {
		e.preventDefault();
		isFunction( props.onClick ) && props.onClick()
	}}>
		<CloseCircleIcon/>
		<span className="d-none"> close </span>
	</a>
)

export default CloseButton;