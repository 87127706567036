import React, { useEffect, useState } from 'react';
import "./mobile.scss";

const MobileLayout = props => {

	const getWindowHeight = () => window.innerHeight;
	const [ w_height, setWHeight ] = useState( getWindowHeight());

	useEffect(() => {

		const calculateProperHeight = () => setWHeight( getWindowHeight());
		window.addEventListener( "resize", calculateProperHeight );

		return () => window.removeEventListener( "resize", calculateProperHeight );

	}, [])
	
	return (
		<div className="mobile-layout" style={{
			maxHeight: w_height,
			height: w_height
		}}>
			<main className="has-scrollbar"> 
				{ props.children }
			</main>
		</div>
	)
}

export default MobileLayout;