import React from 'react';
import { isFunction } from 'extra/functions';
import CloseButton from 'components/buttons/CloseButton';

const ModalHeader = ({ heading, closeHandler }) => (
	<div className="modal__header df-ac">
		<div className="heading">
			{ heading || "Uwaga!" }
		</div>

		{ isFunction( closeHandler ) && <CloseButton onClick={ closeHandler } /> }
	</div>
)


export default ModalHeader;