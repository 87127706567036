import React from 'react';
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "svg/logo_black.svg";
import ThemeControls from 'components/theme/ThemeControls';

import "./header.scss";

const Header = () => (
	<header className="header df-ac">
		<Link to="/" className="header__logo df-centered">
			<Logo />
		</Link>

		<div className="header__title df-ac">
			Aplikacja dla Mieszkańca
		</div>

		<ThemeControls/>
	</header>
)

export default Header;