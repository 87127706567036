import React, { useState, useEffect, useMemo, useContext } from 'react';
import DesktopLayout from 'layout/DesktopLayout';
import MobileLayout from 'layout/MobileLayout';
import AdminDataContext from './AdminDataContext';
import Loader from 'components/general/Loader';
import CookiesInfo from "components/general/CookiesInfo";

const DeviceContext = React.createContext();

export const DeviceContextProvider = props => { 
	
	const { loading } = useContext( AdminDataContext );

	const getDevice = () => window.innerWidth <= 1023 ? window.innerWidth < 920 ? "mobile" : "tablet" : "desktop";
	const [ device, setDevice ] = useState( getDevice());

	const LayoutComponent = useMemo(() => device !== "desktop" ? MobileLayout : DesktopLayout, [ device ]);

	useEffect(() => {

		const windowResize = () => setDevice( getDevice());
		window.addEventListener("resize", windowResize );

		return () => window.removeEventListener("resize", windowResize );
	}, []);


	return (
		<DeviceContext.Provider value={ device }> 
			<CookiesInfo/>
			<LayoutComponent> 
				{ loading ? <Loader/> : props.children }
			</LayoutComponent>
		</DeviceContext.Provider>
	);
}

export default DeviceContext;