import React from 'react';
import { MinusIcon, AAIcon, PlusIcon } from "svg/icons";
import { changeFontSize } from "extra/theme";


const FontSizeSwitcher = () => {

	const items = [
		{
			action: "less",
			title: "Zmniejsz wielkość czcionki",
			icon: "A-"
		},
		{
			action: "more",
			title: "Zwiększ wielkość czcionki",
			icon: "A+"
		}
	];

	return (
		<div className="font-size-switcher df-ac">
			{ items.map(({ action, title, icon }) => (
				<a 
					key={ action }
					href="#"
					className="df-centered"
					title={ title }
					onClick={ e => {
						e.preventDefault();
						changeFontSize( action );
					}}
				> 
					{ icon } 
					<span className="d-none"> { title } </span> 
				</a>

			)) }
		</div>
	)
}


export default FontSizeSwitcher;