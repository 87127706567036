import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { checkAndTurnOnContrastTheme } from "extra/theme";
import Routes from "routes/routes";
import Chat from "extra/chat";

import { DeviceContextProvider } from "context/DeviceContext";
import { AdminDataContextProvider } from "context/AdminDataContext";
import { ModalMessageContextProvider } from "context/ModalMessageContext";

import "styles/main/main.scss";
import "styles/main/contrast.scss";

function App() {

	React.useEffect(() => checkAndTurnOnContrastTheme(), []);

	return (
		<>
			<Router basename={ window.config.BASE_URL || "/" }>
				<Chat/>
		
				<AdminDataContextProvider>
					<DeviceContextProvider>
						<ModalMessageContextProvider>
							<Routes/>
						</ModalMessageContextProvider>
					</DeviceContextProvider>
				</AdminDataContextProvider>
			</Router>
		</>
	)
}

export default App;



