import Axios from "axios";

const API = Axios.create({ 
	baseURL: window.config.API_URL
});

API.getSetting = name => API.get(`/v1/setting/${ name }`);


API.getAllDistricts = () => API.get("/v3/geo/districts");

API.getAllAddresses = () => API.get("/v3/geo/addresses");

API.getAllStreets = () => API.get("/v3/geo/streets"); 

API.getStatuses = () => API.get("/v3/statuses");

API.getStatistics = () => API.get("/v3/stats");

API.getAgreements = () => API.get("/v1/agreements");

API.getCategories = () => API.get("/v1/categories");

API.getMapBanner = () => API.getSetting("map_banner");

API.getGeneralMapTileURL = () => API.getSetting("map_general_tile_url");

API.getGeneralMapMaxZoom = () => API.getSetting("map_general_max_zoom");

API.getSatelliteMapTileURL = () => API.getSetting("map_satellite_tile_url");

API.getSatelliteMapMaxZoom = () => API.getSetting("map_satellite_max_zoom");

// CHECKBOXES
export const checkbox_names = [ "adding-issue", "newsletter", "note-newsletter", "contact-form", "newsletter2", "personal-data-info" ];

API.getCheckbox = name => API.get(`/v1/setting/${ name }-checkbox`);

// SUBSCRIBE EMAIL: POST
/* data = {
	email: required
	biznes_i_nauka: 1 || 0,
	kultura: 1 || 0,
	miasto_lublin: 1 || 0,
	sport: 1 || 0,
	turystyka: 1 || 0
} */
API.subscribeNewsletter = data => API.post("/v2/newsletter_subscribe", {
	...data,
	name: "Imię",
	last_name: "Nazwisko" 
});


// GET ISSUES
/**
	data: { optional
		map: 1 || 0,
		sort_by: str ( id, created_at, statusName ),
		order: "asc" or "desc",
		offset: int, 
		limit: int,
		filters: {
			search: str,
			category:,
			subcategory: 
			status: str,
			district: str,
			date_from: YYYY-MM-DD
			date_to: YYYY-MM-DD
		}
	}
*/ 
API.getIssues = data => {

	const params = {};

	if ( data ) {
		params.isForMap = data.map || 0;
		params.orderBy = data.sort_by;
		params.orderDir = data.order ? "asc" : "desc";
		params.offset = data.offset || 0; 
		params.limit = data.per_page || null;

		const filter_category = data?.filters?.category ? +data?.filters?.category : null;
		const filter_subcategory = data?.filters?.subcategory ? +data?.filters?.subcategory : null; 

		params.search = data?.filters?.search;
		params.categoryId = filter_subcategory || filter_category || null;
		params.subcategories = filter_category && !filter_subcategory ? 1 : null;
		params.statusName = data?.filters?.status;
		params.issueRegion = data?.filters?.district
		params.dateFrom = data?.filters?.date_from;
		params.dateTo = data?.filters?.date_to;
	}

	return API.get("/v3/issues", { params });
}


// CREATE ISSUE: POST
/*
	params: {
		description: required str,
		lat: required double,
		lng: required double,
		email: optional str,
		subcategory or category: required int
		attachments -> files - LIST OF FILES 
	}
*/ 
API.createIssue = params => {

	const data = new FormData();

	data.append( "g-recaptcha-response", params["g-recaptcha-response"] );
	data.append( "issueName", params.name );
	data.append( "issueDescription", params.description );
	data.append( "issueLatitude", params.lat );
	data.append( "issueLongitude", params.lng );
	data.append( "userEmail", params.email );
	data.append( "district", params.district );
	data.append( "street", params.street );
	data.append( "streetNum", params.street_num );
	data.append( "categoryId", params.subcategory || params.category );

	const files = params?.attachments?.files;

	if ( files && files.length ) {
		for ( let i = 0; i < files.length; i++ ) {
			data.append( `photo${ i + 1 }`, files[ i ], files[ i ].name );
		}
	}

	return API.post("/v3/issue", data );
}


API.getCaptcha = () => API.get("/v3/captcha");

// SEND CONTACT FORM
/*
	data: {
		email: required
		text: required
		g-recaptcha-response: required
	}
*/ 
API.sendContactForm = data => API.post("/v3/contact", data );

API.getAddressByLatLng = ( lat, lng ) => API.get(`/v3/address/${lat}/${lng}`);

API.getAddressesByStreet = street_id => API.get(`/v3/street/${ street_id }`)

export default API;