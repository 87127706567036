import React from 'react';
import { UE, Lubelskie, FE, RP } from "svg/logos";

const logos = [ <FE/>, <RP/>, <Lubelskie/>, <UE/> ];

const FooterLogos = () => (
	<div className="footer__logos df-ac">
		{ logos.map(( logo, i ) => (
			<span key={ i }>
				{ logo }
			</span>
		))}
	</div>
)


export default FooterLogos;