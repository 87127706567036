import React from 'react';
import "./button.scss";

const Button = props => {

	const { children, extra_classes, ...rest } = props;

	return (
		<button
			className={`button ${ extra_classes || ""} `}
			{...rest }
		>
			{ children }
		</button>
	)
}


export default Button;