import React from "react";
import { Route, Switch } from "react-router";
import AsyncComponent from "components/AsyncComponent";

const MapPage = AsyncComponent(() => import( "pages/MapPage" ));
const ListPage = AsyncComponent(() => import( "pages/ListPage" ));

const RegulationsPage = AsyncComponent(() => import( "pages/RegulationsPage" ));
const ContactPage = AsyncComponent(() => import( "pages/ContactPage" ));
const NewsletterPage = AsyncComponent(() => import( "pages/NewsletterPage" ));
const AddIssuePage = AsyncComponent(() => import( "pages/AddIssuePage" ));
const NotFoundPage = AsyncComponent(() => import( "pages/NotFoundPage" ));
const PersonalDataInfoPage =  AsyncComponent(() => import( "pages/PersonalDataInfoPage" ));
const RecordingConversationsInfoPage = AsyncComponent(() => import( "pages/RecordingConversationsInfoPage" ));
const PrivacyPolicyPage = AsyncComponent(() => import( "pages/PrivacyPolicyPage" ));
const AccessibilityDeclarationPage = AsyncComponent(() => import( "pages/AccessibilityDeclarationPage" ));

const TestPage = AsyncComponent(() => import( "pages/TestPage" ));

export const ROUTES = {
	Map: { 
		path: "/", 
		component: MapPage, 
		page_title: "Mapa alertów" 
	},
	List: { 
		path: "/list", 
		component: ListPage, 
		page_title: "Lista alertów" 
	},
	Regulations: { 
		path: "/regulations", 
		component: RegulationsPage, 
		page_title: "Regulamin" 
	},
	Contact: { 
		path: "/contact", 
		component: ContactPage, 
		page_title: "Kontakt" 
	},
	Newsletter: { 
		path: "/newsletter", 
		component: NewsletterPage, 
		page_title: "Newsletter" 
	},
	AddIssue: { 
		path: "/add-issue", 
		component: AddIssuePage, 
		page_title: "Zgłoś alert" 
	},
	Search: { 
		path: "/search", 
		component: ListPage, 
		page_title: "Wyszukiwarka" 
	},
	PrivacyPolicy: { 
		path: "/privacy-policy", 
		component: PrivacyPolicyPage, 
		page_title: "Polityka prywatności"
	},
	AccessibilityDeclaration: { 
		path: "/accessibility-declaration", 
		component: AccessibilityDeclarationPage, 
		page_title: "Deklaracja dostępności" 
	},
	PersonalDataInfo: { 
		path: "/personal-data-info", 
		component: PersonalDataInfoPage, 
		page_title: "Klauzula informacyjna Contact Center Urzędu Miasta Lublin" 
	},
	RecordingConversationsInfo: { 
		path: "/recording-conversations-info", 
		component: RecordingConversationsInfoPage, 
		page_title: "Klauzula informacyjna Nagrania rozmów w Contact Center Urzędu Miasta Lublin" 
	},
	Test: { path: "/alex-test", component: TestPage }
};

export const getPageTitleByPathname = pathname => Object.values( ROUTES ).find( item => item.path === pathname )?.page_title

export default () => (
	<Switch>
		{ Object.values( ROUTES ).map( item => (
			<Route 
				key={ item.path } 
				exact
				path={ item.path }
				component={ item.component } 
			/>
		))}

		<Route component={ NotFoundPage }/>
	</Switch>
);