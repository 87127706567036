import React from 'react';
import { SignIcon } from 'svg/icons';

const SignTranslator = () => (
	<a 
		className="sign-translator df-centered"
		href="https://omni-tlumacz.migam.org/queue/Mt8yujsPRHk0AvzcQT30"
		target="_blank"
		title="Tłumacz języka migowego" 
	>
		<SignIcon/>
		<span className="d-none"> sign translator </span>
	</a>
)

export default SignTranslator;