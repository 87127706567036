import React from 'react';
import './loader.scss';

const Loader = ({ style }) => (
	<div className="loader" style={ style }>
		<div className="cssload-whirlpool"></div>
	</div>
)

export default Loader;
