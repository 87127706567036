import React from 'react';
import { Link, useLocation } from "react-router-dom";

import { PlusIcon } from "svg/icons";
import { ROUTES } from "routes/routes";

import sprite_img from "img/sprite_page.png";
import "./menu.scss";


const Menu = () => {

	const location = useLocation();
	
	const menu_items = [
		{
			className: "df-centered to-add-page",
			icon: <PlusIcon/>,
			label: ROUTES.AddIssue.page_title,
			path: ROUTES.AddIssue.path
		},
		{
			label: ROUTES.Map.page_title,
			path: ROUTES.Map.path
		},
		{
			label: ROUTES.List.page_title,
			path: ROUTES.List.path
		},
		{
			label: ROUTES.Regulations.page_title,
			path: ROUTES.Regulations.path
		},
		{
			label: ROUTES.Contact.page_title,
			path: ROUTES.Contact.path
		},
		{
			label: ROUTES.Newsletter.page_title,
			path: ROUTES.Newsletter.path
		}
	]

	return (
		<nav className="menu">
			{ menu_items.map(({ label, icon, className = "", path }) => {
				
				const active_class = location.pathname === path ? "active" : "";

				return (
					<Link 
						key={ path } 
						to={ path }
						className={`${ className } ${ active_class }`} 
						title={ label || "" }
					>
						{ icon }
						{ !icon && <div className="menu__icon" style={{ backgroundImage: `url(${ sprite_img })`}} />}
						<span> { label } </span>
					</Link>
				)
			})}
		</nav>
	)
}


export default Menu;