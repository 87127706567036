import React, { useState } from 'react';
import CloseButton from 'components/buttons/CloseButton';
import { ROUTES } from "routes/routes";

import sprite_img from "img/sprite_page.png";
import "./cookies-info.scss";

export default function CookiesInfo() {
	
	const [ show, setShow ] = useState( !localStorage.getItem( "cookie_info" ));
	const SITE_NAME = window.location.origin.replace(/http?s:\/\//, "");


	if ( !show ) return null;

	return (
		<div className="cookies-info">

			<div className="cookies-info__container">
				<div className="cookies-info__pic" style={{ backgroundImage: `url(${ sprite_img })`}}/>

				<div>
					W celu świadczenia usług na najwyższym poziomie stosujemy pliki cookies. Korzystanie z serwisu { SITE_NAME } oznacza, że będą one zamieszczane w Twoim urządzeniu. W każdym momencie możesz dokonać zmiany ustawień Twojej przeglądarki. Więcej informacji znajdziesz w <a target="_blank" href={ ROUTES.PrivacyPolicy.path }> Polityce prywatności </a>.
				</div>

				<CloseButton onClick={() => {
					setShow( false );
					localStorage.setItem( "cookie_info", true );
				}}/>
			</div>
		</div>
	)
}

