import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { ROUTES } from "routes/routes";
import { loadScript } from "./functions";

const CHAT_URL = window.config.CHAT_URL || "https://test-chat.lublin.eu/livechat"

export default () => {

	const location = useLocation();
	const [ loaded, setLoaded ] = useState( false );

	useEffect(() => {

		if ( !loaded ) {
			
			setLoaded( true );

			const w = window;

			w.RocketChat = function( c ) { w.RocketChat._.push( c )}
			w.RocketChat._ = []; 
			w.RocketChat.url = CHAT_URL;

			loadScript( CHAT_URL + "/rocketchat-livechat.min.js?_=" + Math.random());
		}

	}, [ location.pathname ]);


	useEffect(() => {

		const showHideEl = ( dom_el, is_visible ) => {
			is_visible
				? dom_el.classList.remove( "hidden" )
				: dom_el.classList.add( "hidden" );
		}

		const detectWidgetAndShowHideIt = ( selector, is_visible ) => {
			document.querySelector( selector )
				? showHideEl( document.querySelector( selector ), is_visible )
				: setTimeout(() => detectWidgetAndShowHideIt( selector, is_visible ), 60 )
		}

		detectWidgetAndShowHideIt( ".rocketchat-widget", location.pathname === ROUTES.Contact.path );
		detectWidgetAndShowHideIt( ".grecaptcha-badge", [ ROUTES.Contact.path, ROUTES.AddIssue.path ].includes( location.pathname ));

	}, [ location.pathname ]);

	return null;
}
