import React from 'react';
import FooterLogos from "./FooterLogos";
import FooterLinks from './FooterLinks';
import "./footer.scss";

const Footer = () => (
	<footer className="footer">
		<div className="footer-container">
			
			<div className="df-ac footer__content">
			
				<FooterLogos/>
				
				<p> 
					Projekt "Budowa i rozbudowa e-usług w Gminie Lublin" współfinansowany ze środków Europejskiego Funduszu Rozwoju Regionalnego 
					w ramach Regionalnego Programu Operacyjnego Województwa Lubelskiego na lata 2014-2020
				</p>
			</div>

			<FooterLinks/>
		</div>
	</footer>
)

export default Footer;