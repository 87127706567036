import React from 'react';
import { ContrastIcon } from 'svg/icons';
import { toggleContrastTheme } from "extra/theme";

const ContrastSwitcher = () => (
	<a 
		href="#"
		className="contrast-switcher df-centered"
		title="Kontrast"
		onClick={ e => {
			e.preventDefault();
			toggleContrastTheme();
		}}	
	>
		<ContrastIcon/>
		<span className="d-none"> contrast switcher </span>
	</a>
)

export default ContrastSwitcher;