import React from 'react';
import { ROUTES } from "routes/routes";

const links = [
	{ 
		label: "Ogólna klauzula informacyjna Urzędu Miasta Lublin", 
		href: "https://lublin.eu/ochrona-danych-osobowych/klauzula-informacyjna-dotyczaca-przetwarzania-danych/" 
	},
	{ 
		label: "Inspektor ochrony danych osobowych", 
		href: "https://lublin.eu/ochrona-danych-osobowych/inspektor-ochrony-danych-osobowych/" 
	},
	{ 
		label: "Polityka prywatności", 
		href: ROUTES.PrivacyPolicy.path 
	},
	{ 
		label: "Deklaracja dostępności", 
		href: ROUTES.AccessibilityDeclaration.path 
	}
];

const FooterLinks = () => (
	<div className="footer__links df-ac">
		{ links.map(( link, i ) => (
			<a
				key={ i } 
				href={ link.href }
				target="_blank" 
			>
				{ link.label }
			</a>
		))}
	</div>
)

export default FooterLinks;