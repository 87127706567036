import React from 'react';

import Header from "components/header/Header";
import Sidebar from "components/sidebar/Sidebar";
import Footer from "components/Footer";

import "./desktop.scss";

const DesktopLayout = props => (
	<div className="desktop-layout">
		<Header/>

		<div className="desktop-layout-main-wrap flex">
			
			<Sidebar/>

			<main className="has-scrollbar"> 
				{ props.children }
			</main>
		</div>

		<Footer/>
	</div>
)

export default DesktopLayout;