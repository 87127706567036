import React from 'react';
import ContrastSwitcher from 'components/theme/ContrastSwitcher';
import FontSizeSwitcher from 'components/theme/FontSizeSwitcher';
import SignTranslator from 'components/theme/SignTranslator';

import sprite_img from "img/sprite_header.png";
import "./theme-controls.scss";

const ThemeControls = () => (
	<div className="theme-controls df-ac">
		<SignTranslator/>
		<ContrastSwitcher/>
		<FontSizeSwitcher/>

		<a 
			className="bip-link" 
			href="http://bip.lublin.eu" 
			title="bip.lublin.eu"
			target="_blank"
			style={{ backgroundImage: `url(${ sprite_img })` }}	
		>
			<span className="d-none"> BIP </span> 
			<span className="d-none"> otwiera się w nowej karcie </span>
		</a>
	</div>
)

export default ThemeControls;